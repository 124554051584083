import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';

import { LocalizationProvider } from './components/shared/ReactToolbox';
import Root from './Root';
import Questionaire from './components/questionaire/Questionaire'
class App extends Component {
  render() {
    return (
      <Root>
        <LocalizationProvider lang='nl'>
          <ToastContainer hideProgressBar={true} newestOnTop={true} />
          <Questionaire />
        </LocalizationProvider>
      </Root>
    );
  }
}

export default App;