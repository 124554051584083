import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Link, Switch, withRouter, useMatch, useParams } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import { AiOutlineHome } from 'react-icons/ai';
import _ from 'lodash';

import { mapToProps, actions } from '../../redux/Factory';
import ChoicesLinks, { getChoiceLink } from './ChoicesLinks';
import AnswersList from '../answers/AnswersList';
import QuestionaireName from '../questionaire/QuestionaireName'

const withId = (url, id=':id') => url === '/' ? `/${id}` : `/${id}${url}`;

const getBreadcrumb = ({ url, choice, breadcrumb: Breadcrumb = null }) => props => {
  const { id } = useParams();
  return (
    <>
      {Breadcrumb && <><Breadcrumb /> / </>}
      {choice &&
        <Link
          to={withId(url, id)}
          {...props}
        >
          {choice.name}
          {/* {choice ? choice.name : <AiOutlineHome />} */}
        </Link>
      }
    </>
  );
      }

export const getRoutes = ({
  routes = [],
  url = '/',
  parent = null,
  breadcrumb: Breadcrumb = null,
  choicesList,
  answersList,
} = {}) => {
  if (_.isEmpty(choicesList)) return [];

  routes.push(
    <Route
      exact
      path={withId(url)}
      key={url}
      element={
        <Container style={{ marginTop: '50px'}}>
          <h2>
            {url === '/'
              ? <QuestionaireName />
              : parent.name
            }
          </h2>
          {parent && <>
            <Breadcrumb />
          </>}
          <br />
          <br />
          <ChoicesLinks
            style={{ marginTop: '20px'}}
            parent={parent}
            url={url}
            key={url}
          />
        </Container>
      }
    />
  );
  
  const choices = choicesList[parent ? parent.id : null];
  if (choices) {
    Object.values(choices).map(choice => {
      const childUrl = getChoiceLink(url, choice);
      const ChildBreadcrumb = getBreadcrumb({ url, choice: parent, breadcrumb: Breadcrumb })
      if (!answersList[choice.id]) {
        getRoutes({
          parent: choice,
          url: childUrl,
          breadcrumb: ChildBreadcrumb,
          choicesList,
          answersList,
        }).map(link => routes.push(link))
      } else {
        routes.push(
          <Route
            exact
            path={withId(childUrl)}
            key={childUrl}
            element={
              <Container style={{ marginTop: '50px'}}>
                <h2>{choice.name}</h2>
                {parent && <ChildBreadcrumb />}
                <br />
                <br />
                <AnswersList
                  choice={choice}
                />
              </Container>
            }
          />
        )
      }
    });
  }
  return routes;
}
