
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { mapToProps } from '../../redux/Factory';

const QuestionaireName = () => {
  const { id } = useParams();
  const { questionaire } = useSelector(s => mapToProps.questionaires(s, { id: parseInt(id) }))

  return questionaire && questionaire.name
}

export default QuestionaireName;