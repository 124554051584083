import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Row, Col, Nav } from 'react-bootstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Route, Routes, Outlet, Link } from 'react-router-dom';

import { mapToProps, actions, use } from '../../redux/Factory';
import { getRoutes } from '../choices/ChoicesList';

import { SmallSpinner } from '../shared/ReactToolbox';

const NavLink = ({ to, children }) => <Nav.Item>
  <Link to={to} className='nav-item'>
    <div className='nav-link'>{children}</div>
  </Link>
</Nav.Item>;

const Questionaire = props => {
  useEffect(() => {
    props.getAllChoices();
    props.getAllAnswers();
    props.getFieldDefinitionsList();
    props.getQuestionairesList();
    props.getAllSelectionOptions();
  }, []);
  
  const { questionairesList } = use.questionaires();
  const { choicesList } = use.choices();
  const { answersList } = use.answers();
  
  if (!questionairesList || !answersList || !choicesList) {
    return <SmallSpinner className='vertical-space' />;
  }

  const routes = getRoutes({ choicesList, answersList });

  return (
    <Routes>
      
      <Route
        exact
        path="/"
        element={          
          <Row style={{ width: '100%'}}>
            <Col sm="2" className="sidebar">
              <Nav
                className="d-none d-md-block bg-light fluid"
                style={{ minHeight: '100vh'}}
              >
                <br />
                {Object.values(questionairesList).map((questionaire, key) =>
                  <NavLink to={`/${questionaire.id}`} key={key}>{questionaire.name}</NavLink>
                )}
              </Nav>
            </Col>
            <Col sm="10">
              <Outlet />
            </Col>
          </Row>
        }
      >
          {routes}
        
      </Route>
    </Routes>
  );
}

export default connect(null, {
  ...actions.answers,
  ...actions.choices,
  ...actions.fieldDefinitions,
  ...actions.questionaires,
  ...actions.selectionOptions,
})(Questionaire);

