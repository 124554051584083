import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, ListGroup } from 'react-bootstrap';
import { Route, Link, Switch, useParams } from 'react-router-dom';
import speakingUrl from 'speakingurl';

import { mapToProps, actions } from '../../redux/Factory';

export const getChoiceLink = (url, choice) => `${url === '/' ? '' : url}/${speakingUrl(choice.name)}`;

const ChoicesLinks = ({ url, parent, getChoicesIsLoading, choicesList }) => {
  const { id } = useParams();
  if (getChoicesIsLoading || !choicesList) return null;
  return (
    <ListGroup>
      {Object.values(choicesList)
        .filter(({ questionaire }) => questionaire === parseInt(id))
        .map((choice, key) => {
        const path = getChoiceLink(url, choice);
        
        return <Link
          to={path === '/' ? `/${id}` : `/${id}${path}`}
          key={key}
        >
          <ListGroup.Item>
            {choice.name}
          </ListGroup.Item>
        </Link>}
      )}
    </ListGroup>
  );
}

const ChoicesLinksConnected = connect(mapToProps.choices, actions.choices)(ChoicesLinks);
export default ChoicesLinksConnected;